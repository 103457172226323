function onLoaded() {
  /** @var {HTMLObjectElement} object */
  const object = document.getElementById("map");
  object.addEventListener("load", () => {
    onMapLoaded(object)
  })
}

function onMapLoaded(object) {
  const map = object.getSVGDocument();
  const pathId = document.getElementById("pathId")

  if (!pathId) {
    return
  }

  map.addEventListener("click", ({ target }) => {
    if (target.tagName !== "circle") {
      return
    }

    const current = map.querySelector(".active")
    if (current) {
      current.classList.remove("active")
    }

    target.classList.add("active")
    pathId.innerText = target.id.replace("worldmap-node-", "")
  })
}

document.addEventListener("readystatechange", (event) => {
  if (document.readyState === "interactive") {
    onLoaded()
  }
})
